import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link1 } from '../../../components/images/support/subscriptions/Link1.generated';
import { Link2 } from '../../../components/images/support/subscriptions/Link2.generated';
import { Link3 } from '../../../components/images/support/subscriptions/Link3.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "linking-a-business-to-a-subscription"
    }}>{`Linking a Business to a Subscription`}</h1>
    <p>{`Billing accounts with an Advisor Plan subscription can link multiple businesses to their Canyou subscription. This article
shows how to:`}</p>
    <p><strong parentName="p">{`Note - only the owner of the subscription will have authorisation to complete these steps.`}</strong></p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Click the subscription notification banner.`}</li>
    </ol>

    <Link1 mdxType="Link1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`If you have an existing subscription and you want the business to be linked choose your existing account from the drop down box and click the
`}<strong parentName="li">{`Add to billing account`}</strong>{` button. Alternatively if there is no subscription setup click the `}<strong parentName="li">{`Setup billing account`}</strong>{` button.`}</li>
    </ol>

    <Link2 mdxType="Link2" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Add to billing account`}</strong>{` button.`}</li>
    </ol>

    <Link3 mdxType="Link3" />
    <h1 {...{
      "id": "great-work-the-business-has-been-linked-to-your-subscription-"
    }}>{`Great work the business has been linked to your subscription 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      